import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, LinkButton as LinkButtonWrap } from 'sw-ui';

import LinkToNewTab from '../../../../components/LinkToNewTab';
import EditTransactionForm from './editTransactionForm';
import EditInvoiceForm from './editInvoiceForm';

import { formatDate } from '../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../bi/utils/money';

import { FORMATDATETIME } from '../../../../constants/time';
import FILEFORMATS from '../../../../constants/fileFormats';
import COMPONENTS from '../../../../bi/constants/components';
import { TRIPBUTTONS } from '../../../../bi/constants/trips';

import styles from '../../styles/company.module.scss';

const { LinkButton, LinkButtonItem } = LinkButtonWrap;

const isPenalty = ({ Type, Details }) => Type === 2 && Details.find(detail => detail.Type === 2);

const LABELS = {
  XLSX: 'В формате xlsx',
  XLSX_NOT_STAMP: 'В формате xlsx без подписи и печати',
  ERROR_MSG: 'У вас нет прав на редактирование документов в закрытом периоде',
};

class OperationInvoice extends Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    updateState: PropTypes.func.isRequired,
    companyService: PropTypes.object.isRequired,
    renderPenaltyBySchema: PropTypes.bool.isRequired,
    changeInvoiceNumber: PropTypes.bool.isRequired,
  };

  state = {
    showTransactions: false,
    showEditTransactionForm: false,
    priceDetails: {},
    editInvoice: {
      showDialog: false,
      invoiceNum: '',
      invoice: {},
    },
    flagMsgError: false,
  };

  handleDownloadInvoice = (format, stamp) => {
    const {
      item: { InvoiceNum },
      companyService,
      companyId,
    } = this.props;

    if (!InvoiceNum.includes('/')) {
      return companyService.downloadInvoiceWithoutSlash(companyId, InvoiceNum, format);
    }

    return companyService.downloadInvoice(companyId, InvoiceNum, format, stamp);
  };

  handleDownloadPenalty = (format) => {
    const {
      item: { Details },
      companyService,
      companyId,
    } = this.props;

    companyService.downloadPenalty(companyId, Details[0].OperationId, format);
  };

  handleOpenErrorTransaction = () => this.setState({ flagMsgError: true, showEditTransactionForm: false });

  handleToggleTransactions = () => this.setState({ showTransactions: !this.state.showTransactions });

  handleToggleEditTransactionForm = priceDetails =>
    this.setState({
      showEditTransactionForm: !this.state.showEditTransactionForm,
      priceDetails: !this.state.showEditTransactionForm ? priceDetails : {},
    });

  handleSaveTransaction = priceDetails =>
    this.props.companyService.editTransactionDetails(
      this.props.item.Id,
      priceDetails.OperationDetailsId,
      priceDetails,
    ).then(this.props.updateState)
    .then(this.handleToggleEditTransactionForm)
    .catch((res) => {
      if (res.statusCode === 403) {
        this.handleOpenErrorTransaction();
      }
    });

  saveInvoice = invoice => this.props.companyService.saveInvoice(this.props.companyId, this.state.editInvoice.invoiceNum, invoice);

  openEditInvoiceDialog = (item) => {
    const { companyService, companyId } = this.props;

    companyService.getInvoice(companyId, item.InvoiceNum)
      .then((res) => {
        this.setState({
          editInvoice: {
            showDialog: true,
            invoiceNum: item.InvoiceNum,
            invoice: res,
          },
        });
      });
  };


  closeEditInvoiceDialog = () => this.setState({
    editInvoice: {
      showDialog: false,
      invoiceNum: '',
      invoice: {},
    },
  });

  renderEditTransactionDialog() {
    const {
      showEditTransactionForm,
      priceDetails,
    } = this.state;

    return showEditTransactionForm ?
      <Dialog onClick={ this.handleToggleEditTransactionForm } width='450px'>
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          <EditTransactionForm
            priceDetails={ priceDetails }
            onClose={ this.handleToggleEditTransactionForm }
            onSave={ this.handleSaveTransaction }
          />
        </div>
      </Dialog> : null;
  }

  renderEditInvoiceDialog() {
    const { editInvoice: { showDialog, invoice } } = this.state;
    const { changeInvoiceNumber } = this.props;

    return showDialog ?
      <Dialog onClick={ this.closeEditInvoiceDialog } width='1400px'>
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          <EditInvoiceForm
            invoice={ invoice }
            onClose={ this.closeEditInvoiceDialog }
            onSave={ this.saveInvoice }
            changeInvoiceNumber={ changeInvoiceNumber }
          />
        </div>
      </Dialog> : null;
  }

  renderDownloadButton = () => {
    const { item, renderPenaltyBySchema } = this.props;
    const { InvoiceNum, EventDate } = item;
    const hasPenalty = isPenalty(item);

    if (item.InvoiceNum && !hasPenalty) {
      const label = `Скачать счет на оплату №${InvoiceNum} от ${formatDate(EventDate)}`;

      return (
        <LinkButton
          theme={ COMPONENTS.BUTTON.THEME.OPEN }
          label={ label }
          onClick={ () => this.handleDownloadInvoice(FILEFORMATS.PDF) }
        >
          <LinkButtonItem
            onClick={ () => this.handleDownloadInvoice(FILEFORMATS.XLSX, true) }
          >
            {LABELS.XLSX}
          </LinkButtonItem>
          <LinkButtonItem
            onClick={ () => this.handleDownloadInvoice(FILEFORMATS.XLSX, false) }
          >
            {LABELS.XLSX_NOT_STAMP}
          </LinkButtonItem>
          <LinkButtonItem onClick={ () => this.openEditInvoiceDialog(item) }>
            <span className={ styles.danger }>Изменить счет</span>
          </LinkButtonItem>
        </LinkButton>
      );
    }

    if (hasPenalty && renderPenaltyBySchema) {
      const label = `Скачать счет на штраф от ${formatDate(EventDate)}`;

      return (
        <LinkButton
          theme={ COMPONENTS.BUTTON.THEME.OPEN }
          label={ label }
          onClick={ () => this.handleDownloadPenalty(FILEFORMATS.PDF) }
        >
          <LinkButtonItem
            onClick={ () => this.handleDownloadPenalty(FILEFORMATS.XLSX) }
          >
            {LABELS.XLSX}
          </LinkButtonItem>
          <LinkButtonItem
            onClick={ () => this.handleDownloadInvoice(FILEFORMATS.XLSX, false) }
          >
            {LABELS.XLSX_NOT_STAMP}
          </LinkButtonItem>
        </LinkButton>
      );
    }

    return null;
  }

  render() {
    const {
      item,
      accountId,
    } = this.props;

    const {
      showTransactions,
      flagMsgError,
    } = this.state;
    const { DIALOG: { MIDDLE }, BUTTON: { THEME: { FLAT } } } = COMPONENTS;

    const valueDebit = item.Debit > 0 ? MoneyFormat.moneyWithDecimal(item.Debit, true) : '';
    const valueCredit = item.Credit > 0 ? MoneyFormat.moneyWithDecimal(item.Credit, true) : '';
    const valueTripId = item.TripId ? item.TripId : '';

    const msgErrorRuls = flagMsgError ? (
      <div>
        <Dialog
          show={ flagMsgError }
          onClick={ () => this.setState({ flagMsgError: false }) }
          width={ MIDDLE }
        >
          <div className={ styles.dialog_error }>
            <p>{ LABELS.ERROR_MSG }</p>
            <div className={ `${styles.row} ${styles.right_error}` }>
              <Button
                label={ TRIPBUTTONS.CLOSE }
                theme={ FLAT }
                onClick={ () => this.setState({ flagMsgError: false }) }
              />
            </div>
          </div>
        </Dialog>
      </div>
    ) : null;

    return (
      <div className={ styles.row }>
        <div className={ `${styles.row} ${styles.click}` } onClick={ this.handleToggleTransactions }>
          <div className={ styles['col-1-8'] }>
            { formatDate(item.EventDate, FORMATDATETIME) }
          </div>
          <div className={ styles['col-1-4'] }>
            { item.Description }
          </div>
          <div className={ styles['col-1-8'] }>
            { valueDebit }
          </div>
          <div className={ styles['col-1-8'] }>
            { valueCredit }
          </div>
          <div className={ styles['col-1-8'] }>
            <LinkToNewTab to={ `/account/${accountId}/company/${item.CompanyId}/trip/${item.TripId}` }>
              <span className={ styles.employee }>
                { valueTripId }
              </span>
            </LinkToNewTab>
          </div>
          <div className={ styles['col-1-4'] }>
            { this.renderDownloadButton()}
          </div>
        </div>
        { showTransactions ? item.Details.map(transaction => (
          <div
            key={ `${item.Id}_${transaction.Id}` }
            className={ `${styles.row} ${styles.click} ${styles.transaction}` }
            onClick={ () => this.handleToggleEditTransactionForm(transaction.PriceDetails) }
          >
            <div className={ styles['col-1-8'] } />
            <div className={ styles['col-1-4'] }>
              <i className={ `material-icons ${styles['edit-transaction']}` }>mode_edit</i>
              { transaction.Description }
            </div>
            <div className={ styles['col-1-8'] }>
              { transaction.Amount > 0 ?
                    null : MoneyFormat.moneyWithDecimal(Math.abs(transaction.Amount), true) }
            </div>
            <div className={ styles['col-1-8'] }>
              { transaction.Amount > 0 ?
                  MoneyFormat.moneyWithDecimal(Math.abs(transaction.Amount), true) : null }
            </div>
          </div>
        )) : null }
        { this.renderEditTransactionDialog() }
        { this.renderEditInvoiceDialog() }
        { msgErrorRuls }
      </div>
    );
  }
}

export default OperationInvoice;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input } from 'sw-ui';

import DecisionButtons from '../../../../components/DecisionButtons';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';
import styles from '../../styles/form.module.css';

class NotificationForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    companyService: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const balance = props.details.Notification.LowBalance;

    this.state = {
      enabled: Boolean(balance),
      balance: balance ? String(balance) : '',
      waitingResponse: false,

      validation: {
        balance: '',
      },
    };
  }

  toggleNotification = () => {
    if (!this.state.enabled) {
      return this.setState({ enabled: true });
    }

    return this.setState({
      enabled: false,
      balance: '',
      validation: {
        balance: '',
      },
    });
  };

  validateBalanceInput = (balance) => {
    const message = this.props.companyService.accountingFieldValidation('AlertBalance', balance);

    this.setState({
      validation: {
        balance: message,
      },
    });

    return message === '';
  };

  handleChangeBalance = balance => this.setState({ balance });

  handleSave = () => {
    const { enabled, balance } = this.state;
    const valid = enabled ? this.validateBalanceInput(balance) : true;
    const num = enabled ? balance : null;

    if (valid) {
      const save = () => this.props.onSave(num);

      this.setState({ waitingResponse: true }, save);
    }
  };

  render() {
    const { enabled, balance, waitingResponse, validation } = this.state;
    const { onClose } = this.props;

    return (
      <div>
        <div className={ styles.title }>Оповещения</div>
        <div className={ styles.content }>
          <Checkbox value={ enabled } onChange={ this.toggleNotification }>
            <span>Оповещать при балансе меньше:</span>
          </Checkbox>
          <div className={ styles.content }>
            <Input
              placeholder='50000'
              readOnly={ !enabled }
              value={ balance }
              onChange={ this.handleChangeBalance }
              onBlur={ () => this.validateBalanceInput(balance) }
              error={ validation.balance }
            />
          </div>
        </div>
        <div className={ `${styles.row} ${styles.actions}` }>
          <DecisionButtons
            waitingResponse={ waitingResponse }
            onSave={ this.handleSave }
            onCancel={ onClose }
            labelSave={ DECISIONBUTTONS.LABELS.SAVE }
          />
        </div>
      </div>
    );
  }
}

export default NotificationForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Checkbox } from 'sw-ui';

import { sortingAlphabetically } from '../../../../bi/utils/account';

import {
  PERIODSUPD,
  SENDTYPE,
  UPD,
  CHANGE_UPD,
  NO_SEND,
  PERIOD_SHIPMENT,
  COMMENTS,
  SEND_TYPES,
  TAX_SHEMAS_SA,
} from '../../../../bi/constants/account';
import COMPONENTS from '../../../../bi/constants/components';

import { noPunctuation } from '../../../../bi/constants/regExp';

import AjaxButton from '../../../../components/ajaxButton';
import Input from '../../../../components/input';

import styles from '../../styles/company.module.scss';

const LABEL = {
  SAVE: 'Сохранить',
  FIELD_EDO: 'edo',
  ERROR: 'Ошибка',
  ERROR_VALID: 'Можно указать только следующие символы: цифры, буквы (латинские или кириллицу) и дефисы',
  ERROR_CONDITIONS_SAVE: 'Не удалось сохранить тип отправки. Пожалуйста, укажите обязательные реквизиты и повторите попытку.',
};

const getItems = schemas =>
  Object.keys(schemas).map(schemaKey => ({
    value: schemaKey,
    label: schemas[schemaKey],
  }));

const prepareSendingTypes = (sendingTypes, currentTypes = []) =>
  sendingTypes.map(el => ({ ...el, checked: el.value === NO_SEND ? false : currentTypes.includes(el.value) }));

const normalizeSendingTypes = sendingTypes => sendingTypes
  .reduce((acc, { checked, value }) => (checked ? [...acc, value] : acc), [])
  .join(', ');

class ReportingSettingsForm extends Component {
  static propTypes = {
    currentPeriod: PropTypes.number,
    currentPeriodEdo: PropTypes.string,
    schemas: PropTypes.object.isRequired,
    currentSendingType: PropTypes.string,
    currentSchemas: PropTypes.string.isRequired,
    currentShemasSA: PropTypes.number,
    visible: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onSaveEdo: PropTypes.func.isRequired,
    onSaveShemas: PropTypes.func,
    edo: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    dateCorrection: PropTypes.string.isRequired,
    isSavingCondition: PropTypes.bool,
  };

  static defaultProps = {
    currentSendingType: '',
    currentPeriod: 0,
    currentPeriodEdo: '',
    currentShemasSA: 0,
    visible: 'all', // schema, period
    userName: '',
    dateCorrection: '',
    isSavingCondition: false,
    onSaveShemas: () => null,
  };

  constructor(props) {
    super(props);
    const {
      currentSendingType,
      currentPeriod,
      currentShemasSA,
      currentPeriodEdo,
      currentSchemas,
      schemas,
      edo,
      userName,
      dateCorrection,
      isSavingCondition,
    } = props;
    const sendingTypes = prepareSendingTypes(SENDTYPE, currentSendingType.split(', '));
    const schemaItems = sortingAlphabetically(getItems(schemas));

    this.state = {
      period: currentPeriod,
      periodEdo: currentPeriodEdo,
      schema: currentSchemas,
      taxShemasSA: currentShemasSA,
      sendingTypes,
      schemaItems,
      waitingResponse: false,
      errorMsg: null,
      isDisabledSendingTypes: false,
      edo,
      userName,
      dateCorrection,
      isSavingCondition,
    };
  }

  handleChangePeriod = ({ value }) => this.setState({
    period: value,
  });

  handleChangeShemasSA = ({ value }) => this.setState({
    taxShemasSA: value,
  })

  handleChangePeriodEdo = ({ value }) => this.setState({
    periodEdo: value,
  });

  handleChangeSchema = ({ value }) => this.setState({
    schema: value,
  });

  handleChangeSendingType = (isCheck, value) => {
    const { sendingTypes } = this.state;
    if (value === NO_SEND) {
      const updatedSendingTypes = sendingTypes
        .map(el => (el.value === NO_SEND ? { ...el, checked: isCheck } : { ...el, checked: false }));
      this.setState({ sendingTypes: updatedSendingTypes, isDisabledSendingTypes: isCheck });
      return;
    }

    const updatedSendingTypes = sendingTypes.map(el => (el.value === value ? { ...el, checked: isCheck } : el));
    this.setState({ sendingTypes: updatedSendingTypes });
  }

  saveUPD = () => {
    const { period, periodEdo, schema, sendingTypes, isSavingCondition } = this.state;

    const checkedDiadoc = sendingTypes.find(element => element.value === SEND_TYPES.BY_DIADOC).checked;

    if (checkedDiadoc && isSavingCondition) {
      this.setState({ errorMsg: LABEL.ERROR_CONDITIONS_SAVE });
    } else {
      const save = () => {
        this.props.onSave(period, schema, periodEdo, normalizeSendingTypes(sendingTypes))
        .catch((e) => {
          const msg = e || LABEL.ERROR;
          this.setState({ waitingResponse: false, errorMsg: msg });
        });
      };

      this.setState({ waitingResponse: true }, save);
    }
  };

  saveEdo = () => {
    const { periodEdo, edo } = this.state;

    const save = () => {
      this.props.onSaveEdo(periodEdo, edo);
    };

    this.setState({ waitingResponse: true }, save);
  };

  saveTaxShemasSA = () => {
    const { taxShemasSA } = this.state;
    const { onSaveShemas } = this.props;

    const save = () => {
      onSaveShemas(taxShemasSA);
    };

    this.setState({ waitingResponse: true }, save);
  }

  renderItem = item => (
    <div className={ styles['schema-prompt'] }>{ item.value }</div>
  );

  onChangeInput = (event) => {
    this.setState({
      edo: event.target.value,
    });
  };

  render() {
    const {
      period,
      periodEdo,
      schemaItems,
      schema,
      waitingResponse,
      errorMsg,
      sendingTypes,
      isDisabledSendingTypes,
      edo,
      userName,
      dateCorrection,
      taxShemasSA,
    } = this.state;
    const { visible } = this.props;

    const valueEdo = edo || '';
    const validation = (!noPunctuation.test(valueEdo) && valueEdo !== '');
    const validationMsg = validation ? LABEL.ERROR_VALID : '';

    const errorHtml = errorMsg ? (
      <div className={ styles['error-msg'] }>{ errorMsg }</div>
    ) : null;

    const schemaHtml = (
      <div className={ styles.row }>
        <div className={ styles['text-upd'] }>
          <Select
            items={ schemaItems }
            value={ schema }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ this.handleChangeSchema }
            renderItem={ this.renderItem }
          />
        </div>
      </div>
    );

    const periodHtml = (
      <div className={ styles.row }>
        <div className={ styles['text-upd'] }>
          <Select
            items={ PERIODSUPD }
            value={ period }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ this.handleChangePeriod }
          />
        </div>
      </div>
    );

    const renderTaxShemasSA = (
      <div className={ styles.row }>
        <div className={ styles['text-upd'] }>
          <Select
            items={ TAX_SHEMAS_SA }
            value={ taxShemasSA }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ this.handleChangeShemasSA }
          />
        </div>
      </div>
    );

    const periodEdoHtml = (
      <div className={ styles.row }>
        <div className={ styles['text-upd'] }>
          <Select
            items={ PERIOD_SHIPMENT }
            label={ CHANGE_UPD.PERIOD_EDO }
            value={ periodEdo }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ this.handleChangePeriodEdo }
          />
        </div>
        <div className={ styles['input-upd'] }>
          <Input
            label={ CHANGE_UPD.EDO }
            value={ valueEdo }
            field={ LABEL.FIELD_EDO }
            valid={ validationMsg }
            onChange={ this.onChangeInput }
          />
        </div>
      </div>
    );

    let html = null;
    let title = '';
    let save = this.saveUPD;

    switch (visible) {
      case UPD.ALL: {
        html = <div className={ styles['form-select'] }>{ periodHtml }{ schemaHtml }</div>;
        title = CHANGE_UPD.PERIOD_UPD;

        break;
      }
      case UPD.SCHEMA: {
        html = <div className={ styles['form-select'] }>{ schemaHtml }</div>;
        title = CHANGE_UPD.SCHEMA;

        break;
      }
      case UPD.PERIOD: {
        html = <div className={ styles['form-select'] }>{ periodHtml }</div>;
        title = CHANGE_UPD.PERIOD;

        break;
      }
      case UPD.PERIOD_EDO: {
        html = <div className={ styles['form-select'] }>{ periodEdoHtml }</div>;
        title = CHANGE_UPD.SETTINGS_EDO;
        save = this.saveEdo;

        break;
      }
      case UPD.TAX_SHEMAS_SA: {
        html = <div className={ styles['form-select'] }>{ renderTaxShemasSA }</div>;
        title = CHANGE_UPD.TAX_SHEMAS_SA;
        save = this.saveTaxShemasSA;

        break;
      }
      case UPD.SENDING_TYPE: {
        const sendingTypesHtml = sendingTypes.map(({ label, value, checked }) => (
          <div key={ value } className={ styles.sending_types_item }>
            <Checkbox
              id={ value }
              disabled={ value === NO_SEND ? false : isDisabledSendingTypes }
              value={ checked }
              onChange={ isCheck => this.handleChangeSendingType(isCheck, value) }
            >
              {label}
            </Checkbox>
          </div>
        ));

        html = (
          <div className={ styles['form-select'] }>
            <div className={ styles.row }>
              <div className={ styles['text-upd'] }>
                {sendingTypesHtml}
              </div>
            </div>
          </div>
        );
        title = CHANGE_UPD.SENDING_TYPE;

        break;
      }
    }

    const disabledBySendingTypes = visible === UPD.SENDING_TYPE && sendingTypes.every(({ checked }) => !checked);
    const infoEdo = (visible === UPD.PERIOD_EDO && userName !== null && dateCorrection !== '') &&
      COMMENTS.IntercomComment.INFO_EDO(userName, dateCorrection);

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row} ${styles.title}` }>{ title }</div>
        { html }
        { errorHtml }
        <div className={ `${styles.row} ${styles.action}` }>
          <div className={ `${styles.row} ${styles.subtitle}` }>
            { infoEdo }
          </div>
          <AjaxButton
            label={ LABEL.SAVE }
            disabled={ disabledBySendingTypes || validation }
            loading={ waitingResponse }
            onClick={ save }
          />
        </div>
      </div>
    );
  }
}

export default ReportingSettingsForm;

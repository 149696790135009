import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AjaxButton from '../../../../components/ajaxButton';
import RangeDatePicker from '../../../../components/RangeDatePicker';
import Checkbox from '../../../../components/checkbox';
import Radio from '../../../../components/radio';

import { getMoment, startOfMonth } from '../../../../bi/utils/formatDate';

import { DATE } from '../../../../constants/time';
import { ACCOUNTTEXT } from '../../../../bi/constants/account';
import FILEFORMATS from '../../../../constants/fileFormats';

import styles from '../../styles/company.module.scss';

const LABELS = {
  ALLTIME: 'за все время',
  STAMP: 'С печатью и подписью',
  NOT_STAMP: 'Без печати и подписи',
  SUMMARY_ACCOUNT: 'Скачать сводный счет',
};

class InvoicePaymentTotalForm extends Component {
  static propTypes = {
    onDownload: PropTypes.func.isRequired,
  };

  state = {
    withoutDate: false,
    format: FILEFORMATS.PDF,
    flagStamp: true,
    startDate: startOfMonth(),
    endDate: getMoment(),
    waitingResponse: false,
  };

  handleChangeDate = (field, value) => this.setState({
    [field]: value,
  });

  handleToggleWithoutDate = () => this.setState({ withoutDate: !this.state.withoutDate });

  handleDownload = () => {
    const { withoutDate, startDate, endDate, format, flagStamp } = this.state;

    const startDateValue = startDate.format(DATE);
    const endDateValue = endDate.format(DATE);

    if (withoutDate) {
      this.props.onDownload({ format, flagStamp });
    } else {
      this.props.onDownload({ format, flagStamp, startDateValue, endDateValue });
    }

    this.setState({
      waitingResponse: true,
    });
  };

  handleChangeFormat = (format) => {
    const valueFlagStamp = format === FILEFORMATS.PDF ? true : this.state.flagStamp;

    this.setState({
      flagStamp: valueFlagStamp,
      format,
    });
  };

  handleFlagStamp = (flagStamp) => {
    this.setState({
      flagStamp,
    });
  };

  render() {
    const {
      withoutDate,
      startDate,
      endDate,
      format,
      flagStamp,
      waitingResponse,
    } = this.state;

    const valueDisabled = format === FILEFORMATS.PDF;

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <p className={ `${styles.row} ${styles.title}` }>{LABELS.SUMMARY_ACCOUNT}</p>
        <div className={ styles.row }>
          <RangeDatePicker
            startDate={ startDate }
            endDate={ endDate }
            onChange={ this.handleChangeDate }
            placeholderNameFrom={ ACCOUNTTEXT.FROM }
            placeholderNameTo={ ACCOUNTTEXT.TO }
            disabledTo={ withoutDate }
            disabledFrom={ withoutDate }
          />
        </div>
        <div className={ `${styles.revise__radio}` }>
          <Checkbox
            field={ LABELS.STAMP }
            label={ LABELS.STAMP }
            value={ flagStamp }
            onChange={ () => this.handleFlagStamp(!flagStamp) }
            disabled={ valueDisabled }
          />
        </div>
        <div className={ `${styles.revise__radio}` }>
          <Checkbox
            field={ LABELS.NOT_STAMP }
            label={ LABELS.NOT_STAMP }
            value={ !flagStamp }
            onChange={ () => this.handleFlagStamp(!flagStamp) }
            disabled={ valueDisabled }
          />
        </div>
        <div className={ `${styles.row} ${styles['upd-row']} ${styles['row-between']}` }>
          <div>
            <Checkbox
              field={ LABELS.ALLTIME }
              value={ withoutDate }
              label={ LABELS.ALLTIME }
              onChange={ this.handleToggleWithoutDate }
            />
          </div>
          <div className={ styles.revise__upd }>
            <div className={ `${styles.revise__radio} ${styles.revise__margin}` }>
              <Radio
                className={ styles.revise__radiobutton }
                label={ FILEFORMATS.PDF }
                checked={ valueDisabled }
                field={ FILEFORMATS.PDF }
                value={ FILEFORMATS.PDF }
                onChange={ () => this.handleChangeFormat(FILEFORMATS.PDF) }
              />
            </div>
            <div className={ styles.revise__radio }>
              <Radio
                className={ styles.revise__radiobutton }
                label={ FILEFORMATS.XLSX }
                checked={ format === FILEFORMATS.XLSX }
                field={ FILEFORMATS.XLSX }
                value={ FILEFORMATS.XLSX }
                onChange={ () => this.handleChangeFormat(FILEFORMATS.XLSX) }
              />
            </div>
          </div>
        </div>
        <div className={ `${styles.row} ${styles.action}` }>
          <AjaxButton
            label='Скачать'
            loading={ waitingResponse }
            onClick={ this.handleDownload }
          />
        </div>
      </div>
    );
  }
}

export default InvoicePaymentTotalForm;
